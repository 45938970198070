import { Dispatch, createSlice } from "@reduxjs/toolkit"
import { IComponentState, IToastState } from "../../types/store";

const initialState = {
  appLoader: false,
  sideNavMode: false,
  mobileNavMode: false,
  toasts: []
}

const componentSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAddToast: (state: IComponentState, { payload: toast }: {payload: IToastState}): void => {
      state.toasts = [...state.toasts, toast]
    },
    setRemoveToast: (state: IComponentState, { payload: id }: {payload: string}): void => {
      const updatedToasts = state.toasts.filter((toast) => toast.id !== id);
      state.toasts = [...updatedToasts];
    },
    setToggleAppLoader: (state: IComponentState, {payload: data}: {payload: boolean}): void => {
      state.appLoader = data;
    },
    setToggleSideMenu: (state: IComponentState): void => {
      state.sideNavMode = !state.sideNavMode;
    },
    setToggleMobileNav: (state: IComponentState): void => {
      state.mobileNavMode = !state.mobileNavMode;
    }
  }
});

export const addToast = async (dispatch: Dispatch, payload: IToastState): Promise<void> => {
    dispatch(setAddToast(payload));
};

export const removeToast = async (dispatch: Dispatch, toastId: string): Promise<void> => {
  dispatch(setRemoveToast(toastId));
}

export const toggleApploader = async (dispatch: Dispatch, state: boolean): Promise<void> => {
  dispatch(setToggleAppLoader(state));
}

export const toggleSideMenu = async (dispatch: Dispatch): Promise<void> => {
  dispatch(setToggleSideMenu());
}

export const toggleMobileNav = async (dispatch: Dispatch): Promise<void> => {
  dispatch(setToggleMobileNav());
}



export const {
  setAddToast,
  setRemoveToast,
  setToggleSideMenu,
  setToggleAppLoader,
  setToggleMobileNav,
} = componentSlice.actions;

export default componentSlice.reducer;

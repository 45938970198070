import {backOfficeBaseQuery} from "./backoffice.slice";
import {createApi} from "@reduxjs/toolkit/query/react";


export const inventoryQuery = createApi({
  baseQuery: backOfficeBaseQuery,
  reducerPath: 'inventoryQuery',
  tagTypes: [
    'category', 'item', 'item_settings', 'reconciliation', 'stock_adjustment', 'stock_issue',
    'stock_transfer', 'unit_of_measurement', 'warehouse'
  ],
  endpoints: (builder) => ({
    /** CATEGORY */
    getCategory: builder.query({
      query: (filter) => ({url: `/inventory/category/${filter}`, headers: {admin: 'admin'}}),
      providesTags: ['category']
    }),
    addCategory: builder.mutation({
      query: payload => ({url: `/inventory/category/`, method: "POST", body: payload}),
      invalidatesTags: ['category']
    }),
    importCategories: builder.mutation({
      query: payload => ({url: `/inventory/category/import_category/`, method: "POST", body: payload}),
      invalidatesTags: ['category']
    }),
    exportCategories: builder.mutation({
      query: payload => ({url: `/inventory/category/export_category/`, method: "POST", body: payload}),
      invalidatesTags: ['category']
    }),
    updateCategory: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/category/${id}/`, method: "PUT", body: payload}),
      invalidatesTags: ['category']
    }),
    toggleCategoryStatus: builder.mutation({
      query: ({id, suffix}) => ({url: `/inventory/category/${id}/${suffix}/`, method: "GET"}),
      invalidatesTags: ['category']
    }),
    /** RECONCILIATION */
    getInventoryReconciliation: builder.query({
      query: (filter) => ({url: `/inventory/stock-reconcilliation/${filter}`, headers: {admin: 'admin'}}),
      providesTags: ['reconciliation']
    }),
    addInventoryReconciliation: builder.mutation({
      query: payload => ({url: `/inventory/stock-reconcilliation/`, method: "POST", body: payload}),
      invalidatesTags: ['reconciliation']
    }),
    exportInventoryReconciliation: builder.mutation({
      query: payload => ({
        url: `/inventory/stock-reconcilliation/export_stock_reconcilliation/`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['reconciliation']
    }),
    updateInventoryReconciliation: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/stock-reconcilliation/${id}/`, method: "PUT", body: payload}),
      invalidatesTags: ['reconciliation']
    }),
    deleteInventoryReconciliation: builder.mutation({
      query: id => ({url: `/inventory/stock-reconcilliation/${id}/delete_stock_reconcilliation/`, method: "GET"}),
      invalidatesTags: ['reconciliation']
    }),
    cancelInventoryReconciliation: builder.mutation({
      query: id => ({url: `/inventory/stock-reconcilliation/${id}/cancel_stock_reconcilliation/`, method: "GET"}),
      invalidatesTags: ['reconciliation']
    }),
    submitInventoryReconciliation: builder.mutation({
      query: id => ({url: `/inventory/stock-reconcilliation/${id}/submit_stock_reconcilliation/`, method: "GET"}),
      invalidatesTags: ['reconciliation']
    }),
    addInventoryReconciliationNote: builder.mutation({
      query: ({id, payload}) => ({
        url: `/inventory/stock-reconcilliation/${id}/add_stock_reconcilliation_note/`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['reconciliation']
    }),
    /** ITEM */
    getItem: builder.query({
      query: (filter) => ({url: `/inventory/item/${filter}`, headers: {admin: 'admin'}}),
      providesTags: ['item']
    }),
    addItem: builder.mutation({
      query: ({payload, header}) => {
        let headers = {};
        if (header) headers = {...header};
        return ({url: `/inventory/item/`, method: "POST", body: payload, headers})
      },
      invalidatesTags: ['item']
    }),
    importItems: builder.mutation({
      query: payload => ({url: `/inventory/item/import_item/`, method: "POST", body: payload}),
      invalidatesTags: ['item']
    }),
    exportItems: builder.mutation({
      query: payload => ({url: `/inventory/item/export_item/`, method: "POST", body: payload}),
      invalidatesTags: ['item']
    }),
    updateItem: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/item/${id}/`, method: "PUT", body: payload}),
      invalidatesTags: ['item']
    }),
    uploadItemImage: builder.mutation({
      query: ({id, payload}) => ({
        url: `/inventory/item/${id}/update_item_picture/`,
        method: "POST",
        body: payload,
        headers: {images: "['item_image']", admin: 'admin'}
      }),
      invalidatesTags: ['item']
    }),
    addItemNote: builder.mutation({
      query: ({id, payload}) => ({
        url: `/inventory/item/${id}/add_item_note/`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['item']
    }),
    toggleItemStatus: builder.mutation({
      query: ({id, suffix}) => ({url: `/inventory/item/${id}/${suffix}/`, method: "GET"}),
      invalidatesTags: ['item']
    }),
    updateItemStockControl: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/item/${id}/stock_control/`, method: "POST", body: payload}),
      invalidatesTags: ['item']
    }),
    addItemUOMVariation: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/item/${id}/add_uom_variation/`, method: "POST", body: payload}),
      invalidatesTags: ['item']
    }),
    updateItemUOMVariation: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/item/${id}/update_uom_variation/`, method: "PUT", body: payload}),
      invalidatesTags: ['item']
    }),
    deleteItemUOMVariation: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/item/${id}/delete_uom_variation/`, method: "POST", body: payload}),
      invalidatesTags: ['item']
    }),
    addItemPriceVariation: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/item/${id}/add_price_variation/`, method: "POST", body: payload}),
      invalidatesTags: ['item']
    }),
    updateItemPriceVariation: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/item/${id}/update_price_variation/`, method: "PUT", body: payload}),
      invalidatesTags: ['item']
    }),
    deleteItemPriceVariation: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/item/${id}/delete_price_variation/`, method: "POST", body: payload}),
      invalidatesTags: ['item']
    }),

    /** SETTINGS */
    getItemSettings: builder.query({
      query: () => `/inventory/itemsettings/`,
      providesTags: ['item_settings']
    }),
    addItemSettings: builder.mutation({
      query: (payload) => ({url: `/inventory/itemsettings/`, method: "POST", body: payload}),
      invalidatesTags: ['item_settings']
    }),
    updateItemSettings: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/itemsettings/${id}/`, method: "PUT", body: payload}),
      invalidatesTags: ['item_settings']
    }),
    getUOM: builder.query({
      query: filter => `/inventory/uom/${filter}`,
      providesTags: ['unit_of_measurement']
    }),
    addUOM: builder.mutation({
      query: (payload) => ({url: `/inventory/uom/`, method: "POST", body: payload}),
      invalidatesTags: ['unit_of_measurement']
    }),
    updateUOM: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/uom/${id}/`, method: "PUT", body: payload}),
      invalidatesTags: ['unit_of_measurement']
    }),
    deleteUOM: builder.mutation({
      query: (id) => ({url: `/inventory/uom/${id}/delete_uom/`, method: "GET"}),
      invalidatesTags: ['unit_of_measurement']
    }),
    getWarehouse: builder.query({
      query: filter => `/inventory/warehouse/${filter}`,
      providesTags: ['warehouse']
    }),
    getWarehouseByLocation: builder.mutation({
      query: locationID => ({url: `/inventory/warehouse/${locationID}/check_warehouse/`, method: "GET"}),
      invalidatesTags: ['warehouse']
    }),
    addWarehouse: builder.mutation({
      query: (payload) => ({url: `/inventory/warehouse/`, method: "POST", body: payload}),
      invalidatesTags: ['warehouse']
    }),
    updateWarehouse: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/warehouse/${id}/`, method: "PUT", body: payload}),
      invalidatesTags: ['warehouse']
    }),
    deleteWarehouse: builder.mutation({
      query: (id) => ({url: `/inventory/warehouse/${id}/delete_warehouse/`, method: "GET"}),
      invalidatesTags: ['warehouse']
    }),
    /** STOCK ADJUSTMENT */
    getStockAdjustment: builder.query({
      query: (filter) => ({url: `/inventory/stock-adjustment/${filter}`, headers: {admin: 'admin'}}),
      providesTags: ['stock_adjustment']
    }),
    addStockAdjustment: builder.mutation({
      query: payload => ({url: `/inventory/stock-adjustment/`, method: "POST", body: payload}),
      invalidatesTags: ['stock_adjustment']
    }),
    exportStockAdjustment: builder.mutation({
      query: payload => ({url: `/inventory/stock-adjustment/export_stock_adjustment/`, method: "POST", body: payload}),
      invalidatesTags: ['stock_adjustment']
    }),
    updateStockAdjustment: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/stock-adjustment/${id}/`, method: "PUT", body: payload}),
      invalidatesTags: ['stock_adjustment']
    }),
    deleteStockAdjustment: builder.mutation({
      query: id => ({url: `/inventory/stock-adjustment/${id}/delete_stock_issue/`, method: "GET"}),
      invalidatesTags: ['stock_adjustment']
    }),
    cancelStockAdjustment: builder.mutation({
      query: id => ({url: `/inventory/stock-adjustment/${id}/cancel_stock_issue/`, method: "GET"}),
      invalidatesTags: ['stock_adjustment']
    }),
    submitStockAdjustment: builder.mutation({
      query: id => ({url: `/inventory/stock-adjustment/${id}/submit_stock_issue/`, method: "GET"}),
      invalidatesTags: ['stock_adjustment']
    }),
    addStockAdjustmentNote: builder.mutation({
      query: ({id, payload}) => ({
        url: `/inventory/stock-adjustment/${id}/add_stock_issue_note/`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['stock_issue']
    }),
    /** STOCK ISSUE */
    getStockIssue: builder.query({
      query: (filter) => ({url: `/inventory/stock-issue/${filter}`, headers: {admin: 'admin'}}),
      providesTags: ['stock_issue']
    }),
    addStockIssue: builder.mutation({
      query: payload => ({url: `/inventory/stock-issue/`, method: "POST", body: payload}),
      invalidatesTags: ['stock_issue']
    }),
    exportStockIssue: builder.mutation({
      query: payload => ({url: `/inventory/stock-issue/export_stock_issue/`, method: "POST", body: payload}),
      invalidatesTags: ['stock_issue']
    }),
    updateStockIssue: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/stock-issue/${id}/`, method: "PUT", body: payload}),
      invalidatesTags: ['stock_issue']
    }),
    deleteStockIssue: builder.mutation({
      query: id => ({url: `/inventory/stock-issue/${id}/delete_stock_issue/`, method: "GET"}),
      invalidatesTags: ['stock_issue']
    }),
    cancelStockIssue: builder.mutation({
      query: id => ({url: `/inventory/stock-issue/${id}/cancel_stock_issue/`, method: "GET"}),
      invalidatesTags: ['stock_issue']
    }),
    submitStockIssue: builder.mutation({
      query: id => ({url: `/inventory/stock-issue/${id}/submit_stock_issue/`, method: "GET"}),
      invalidatesTags: ['stock_issue']
    }),
    addStockIssueNote: builder.mutation({
      query: ({id, payload}) => ({
        url: `/inventory/stock-issue/${id}/add_stock_issue_note/`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['stock_issue']
    }),
    /** STOCK TRANSFER */
    getStockTransfer: builder.query({
      query: (filter) => ({url: `/inventory/stock-transfer/${filter}`, headers: {admin: 'admin'}}),
      providesTags: ['stock_transfer']
    }),
    addStockTransfer: builder.mutation({
      query: ({payload, header}) => {
        let headers = {};
        if (header) headers = {...header};
        return ({url: `/inventory/stock-transfer/`, method: "POST", body: payload, headers})
      },
      invalidatesTags: ['stock_transfer']
    }),
    importStockTransfer: builder.mutation({
      query: payload => ({url: `/inventory/stock-transfer/import_stock_transfer/`, method: "POST", body: payload}),
      invalidatesTags: ['stock_transfer']
    }),
    exportStockTransfer: builder.mutation({
      query: payload => ({url: `/inventory/stock-transfer/export_stock_transfer/`, method: "POST", body: payload}),
      invalidatesTags: ['stock_transfer']
    }),
    updateStockTransfer: builder.mutation({
      query: ({id, payload}) => ({url: `/inventory/stock-transfer/${id}/`, method: "PUT", body: payload}),
      invalidatesTags: ['stock_transfer']
    }),
    deleteStockTransfer: builder.mutation({
      query: id => ({url: `/inventory/stock-transfer/${id}/delete_stock_issue/`, method: "GET"}),
      invalidatesTags: ['stock_transfer']
    }),
    cancelStockTransfer: builder.mutation({
      query: id => ({url: `/inventory/stock-transfer/${id}/cancel_stock_transfer/`, method: "GET"}),
      invalidatesTags: ['stock_transfer']
    }),
    submitStockTransfer: builder.mutation({
      query: id => ({url: `/inventory/stock-transfer/${id}/submit_stock_transfer/`, method: "GET"}),
      invalidatesTags: ['stock_transfer']
    }),
    addStockTransferNote: builder.mutation({
      query: ({id, payload}) => ({
        url: `/inventory/stock-transfer/${id}/add_stock_transfer_note/`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['stock_transfer']
    }),
  })
});

export const {
  useGetCategoryQuery,
  useAddCategoryMutation,
  useImportCategoriesMutation,
  useExportCategoriesMutation,
  useUpdateCategoryMutation,
  useToggleCategoryStatusMutation,

  useGetInventoryReconciliationQuery,
  useAddInventoryReconciliationMutation,
  useExportInventoryReconciliationMutation,
  useUpdateInventoryReconciliationMutation,
  useDeleteInventoryReconciliationMutation,
  useCancelInventoryReconciliationMutation,
  useSubmitInventoryReconciliationMutation,
  useAddInventoryReconciliationNoteMutation,

  useGetItemQuery,
  useAddItemMutation,
  useImportItemsMutation,
  useExportItemsMutation,
  useUpdateItemMutation,
  useUploadItemImageMutation,
  useAddItemNoteMutation,
  useToggleItemStatusMutation,
  useUpdateItemStockControlMutation,
  useAddItemUOMVariationMutation,
  useUpdateItemUOMVariationMutation,
  useDeleteItemUOMVariationMutation,
  useAddItemPriceVariationMutation,
  useUpdateItemPriceVariationMutation,
  useDeleteItemPriceVariationMutation,


  useGetItemSettingsQuery,
  useAddItemSettingsMutation,
  useUpdateItemSettingsMutation,
  useGetUOMQuery,
  useAddUOMMutation,
  useUpdateUOMMutation,
  useDeleteUOMMutation,
  useGetWarehouseQuery,
  useGetWarehouseByLocationMutation,
  useAddWarehouseMutation,
  useUpdateWarehouseMutation,
  useDeleteWarehouseMutation,

  useGetStockAdjustmentQuery,
  useAddStockAdjustmentMutation,
  useExportStockAdjustmentMutation,
  useUpdateStockAdjustmentMutation,
  useDeleteStockAdjustmentMutation,
  useCancelStockAdjustmentMutation,
  useSubmitStockAdjustmentMutation,
  useAddStockAdjustmentNoteMutation,

  useGetStockIssueQuery,
  useAddStockIssueMutation,
  useExportStockIssueMutation,
  useUpdateStockIssueMutation,
  useDeleteStockIssueMutation,
  useCancelStockIssueMutation,
  useSubmitStockIssueMutation,
  useAddStockIssueNoteMutation,

  useGetStockTransferQuery,
  useAddStockTransferMutation,
  useImportStockTransferMutation,
  useExportStockTransferMutation,
  useUpdateStockTransferMutation,
  useDeleteStockTransferMutation,
  useCancelStockTransferMutation,
  useSubmitStockTransferMutation,
  useAddStockTransferNoteMutation,
} = inventoryQuery;

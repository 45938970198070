import { HttpStatusCode } from 'axios';
import { createSlice, Dispatch, Store } from '@reduxjs/toolkit';

import {IAuthState, ToastContextProps} from '../../types/store';
import { toggleApploader } from './component.slice';
import { AppKeys } from '../../utils/helpers/constants';
import TokenHelper from "../../utils/helpers/tokenHelper";
import APIService from '../../utils/services/api.service';
import CookieHelper from '../../utils/helpers/cookieHelper';
import { MockUserProfile } from '../../utils/mocks/Profile';
import { getBaseUrlEnv } from '../../utils/services/backoffice.service';
import { AppDomainStatus, Environment, Loading, MenuType, SubMenuType } from '../../types/enums';

const env = getBaseUrlEnv();
const ENVIRONMENT_VARIABLES = process.env;

const initialState = {
  token: '',
  userProfile: MockUserProfile,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state: IAuthState, { payload: tokenData }: { payload: string }): void => {
      state.token = tokenData;
    },
    setProfile: (state: IAuthState, { payload: userProfile }: { payload: IAuthenticationUser }): void => {
      state.userProfile = userProfile;
    },
  }
});

export const onRTKPersist = async (store: Store, toast: ToastContextProps): Promise<void> => {
  let userAuthenticated: boolean = TokenHelper.isAuthenticationValid();
  if (userAuthenticated) getProfile(store.dispatch).catch(err => console.log(err));
  else await getTenantDomain(store.dispatch, toast).catch(err => console.log(err));
}

export const onAuthentication = async (dispatch: Dispatch, responseData: IAuthResponse) => {
  TokenHelper.saveUser(responseData.user);
  TokenHelper.saveToken(responseData.access);

  if (TokenHelper.isAuthenticationValid()) {
    getProfile(dispatch).catch(err => console.log(err))
  }
};

export const getTenantDomain = async (dispatch: Dispatch, toast: ToastContextProps): Promise<AppDomainStatus> => {

  const hostname = window.location.hostname; let verificationState = AppDomainStatus.VERIFIED;
  CookieHelper.remove(ENVIRONMENT_VARIABLES.REACT_APP_DOMAIN ?? AppKeys.DOMAIN);

  if (hostname === ENVIRONMENT_VARIABLES.REACT_APP_LOCALHOST
    || hostname === ENVIRONMENT_VARIABLES.REACT_APP_VERCEL_HOSTNAME
    || hostname === ENVIRONMENT_VARIABLES.REACT_APP_STAGING_HOSTNAME
    || hostname === ENVIRONMENT_VARIABLES.REACT_APP_PRODUCTION_HOSTNAME) {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const uniqueUrl = params.get('unique_url');
    if (uniqueUrl) TokenHelper.saveDomain(hostname);
    verificationState = AppDomainStatus.NON_VERIFIED;
  } else if (hostname === ENVIRONMENT_VARIABLES.REACT_APP_ADMIN_STAGING_HOSTNAME
    || hostname === ENVIRONMENT_VARIABLES.REACT_APP_ADMIN_PRODUCTION_HOSTNAME) {
    verificationState = AppDomainStatus.NON_VERIFIED;
    const newOrigin = `https://${env === Environment.SANDBOX ? "saasmgr.phaneumdev.com" : "saasmgr.phaneum.com"}/`;
    window.location.replace(newOrigin.replace(/\s+/g, ''));
  } else {
    await toggleApploader(dispatch, true);
    const payload = { type: "business_url", value: hostname };
    const response = await APIService.verifyAccount(payload);
    if (response && response.data && response.data.status) {
      if (response.data.status === Loading.SUCCESS) {
        TokenHelper.saveDomain(hostname);
        verificationState = AppDomainStatus.VERIFIED;
      } else if (response.data.status === Loading.FAILED) {
        const newHostName = env === Environment.SANDBOX ? ENVIRONMENT_VARIABLES.REACT_APP_STAGING_HOSTNAME : ENVIRONMENT_VARIABLES.REACT_APP_PRODUCTION_HOSTNAME;
        const newOrigin = `https://${newHostName}`;
        const newPath = `${window.location.pathname}${window.location.search}`;
        const defaultPath = `/${MenuType.AUTH}/${SubMenuType.VERIFY_DOMAIN}`;
        const url = `${newOrigin}${newPath ? newPath : defaultPath}`;
        window.location.replace(url.replace(/\s+/g, ''));
        verificationState = AppDomainStatus.NON_VERIFIED;
      }
    } else {
      verificationState = AppDomainStatus.NON_VERIFIED;
      toast.error("Wrong URL", "Please try again, An Error Occurred while verifying your domain please try again or contact admin");
    }
    toggleApploader(dispatch, false);
  }
  TokenHelper.saveDomainStatus(verificationState)
  return verificationState;
};

export const getProfile = async (dispatch: Dispatch): Promise<void> => {
  let token = TokenHelper.getToken(), user = TokenHelper.getUser();

  if (user && user["tenant_details"] && user["tenant_details"]["id"]) {
    await APIService.getTenants(`${user["tenant_details"]["id"]}/`).then(response => {
      let profile = response['data']['data'];
      dispatch(setAuth(token))
      dispatch(setProfile(profile))
    }).catch(async (error) => {
      if (error && error.status === 401 as HttpStatusCode) {
        const status = TokenHelper.getDomainStatus();
        CookieHelper.remove(ENVIRONMENT_VARIABLES.REACT_APP_KEY ?? AppKeys.TOKEN_KEY);
        window.location.href = `/${MenuType.AUTH}/${status === AppDomainStatus.VERIFIED ? SubMenuType.LOGIN : SubMenuType.VERIFY_DOMAIN}/`;
      } else console.error(error)
    });
  } else removeAuthentication();
}

export const removeAuthentication = async (): Promise<void> => {
  const status = TokenHelper.getDomainStatus();
  window.location.href = `/${MenuType.AUTH}/${status === AppDomainStatus.VERIFIED ? SubMenuType.LOGIN : SubMenuType.VERIFY_DOMAIN}/`;
  APIService.logout();
  CookieHelper.clear();
  CookieHelper.remove(ENVIRONMENT_VARIABLES.REACT_APP_KEY ?? AppKeys.TOKEN_KEY);
}

export const { setAuth, setProfile } = authSlice.actions;

export default authSlice.reducer;

const AppLoader = () => {
  return (
    <div className="app-loader-modal">
      <div className="loader-modal" >
        <div className="spinner" />
      </div>
    </div>
  )
}

export default AppLoader;
const PageLoader = () => {
	return (
		<div className="app-loader-modal">
			<div className="loader-modal">
				<div className="debounce">
					<div className="double-bounce1" />
					<div className="double-bounce2" />
				</div>
			</div>
		</div>
	)
}
export default PageLoader;

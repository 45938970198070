export enum ActionPromptEnum {
  LOGOUT = "LOGOUT",
  ADD_NOTE = "ADD_NOTE",
  RENEW_PLAN = "RENEW_PLAN",
  DELETE_UOM = "DELETE_UOM",
  DELETE_LOGO = "DELETE_LOGO",
  DELETE_USER = "DELETE_USER",
  SAVE_CHANGES = "SAVE_CHANGES",
  ITEMS_EXPORT = "ITEMS_EXPORT",
  PRINT_INVOICE = "PRINT_INVOICE",
  DELETE_ITEM_UOM = "DELETE_ITEM_UOM",
  CUSTOMERS_EXPORT = "CUSTOMERS_EXPORT",
  DELETE_WAREHOUSE = "DELETE_WAREHOUSE",
  DELETE_ITEM_PRICE = "DELETE_ITEM_PRICE",
  CATEGORIES_EXPORT = "CATEGORIES_EXPORT",
  DELETE_ITEM_STOCK = "DELETE_ITEM_STOCK",

  DELETE_STOCK_ISSUE = "DELETE_STOCK_ISSUE",
  CANCEL_STOCK_ISSUE = "CANCEL_STOCK_ISSUE",
  SUBMIT_STOCK_ISSUE = "SUBMIT_STOCK_ISSUE",
  STOCK_ISSUE_EXPORT = "STOCK_ISSUE_EXPORT",

  DELETE_STOCK_TRANSFER = "DELETE_STOCK_TRANSFER",
  CANCEL_STOCK_TRANSFER = "CANCEL_STOCK_TRANSFER",
  SUBMIT_STOCK_TRANSFER = "SUBMIT_STOCK_TRANSFER",
  STOCK_TRANSFER_EXPORT = "STOCK_TRANSFER_EXPORT",

  DELETE_STOCK_ADJUSTMENT = "DELETE_STOCK_ADJUSTMENT",
  CANCEL_STOCK_ADJUSTMENT = "CANCEL_STOCK_ADJUSTMENT",
  SUBMIT_STOCK_ADJUSTMENT = "SUBMIT_STOCK_ADJUSTMENT",
  STOCK_ADJUSTMENT_EXPORT = "STOCK_ADJUSTMENT_EXPORT",

  DELETE_INVENTORY_RECONCILIATION = "DELETE_INVENTORY_RECONCILIATION",
  CANCEL_INVENTORY_RECONCILIATION = "CANCEL_INVENTORY_RECONCILIATION",
  SUBMIT_INVENTORY_RECONCILIATION = "SUBMIT_INVENTORY_RECONCILIATION",
  INVENTORY_RECONCILIATION_EXPORT = "INVENTORY_RECONCILIATION_EXPORT",

  SEND_CREDIT_STATEMENT = "SEND_CREDIT_STATEMENT",
  DELETE_BUSINESS_LOCATION = "DELETE_BUSINESS_LOCATION",
  DELETE_ITEM_SELLING_PRICE = "DELETE_ITEM_SELLING_PRICE",
  CREDIT_STATEMENT_DOWNLOAD = "CREDIT_STATEMENT_DOWNLOAD",
}

export enum ActionPropsType {
  EXPORT = "EXPORT"
}

export enum AddonEnums {
  USER = "USER",
  DEVICE = "DEVICE",
  LOCATION = "LOCATION"
}

export enum AdminRoleEnums {
  SERVICE_STAFF = "SERVICE-STAFF",
  MANAGEMENT_STAFF = "MANAGEMENT-STAFF",
}

export enum AppDomainStatus {
  VERIFIED = "VERIFIED",
  NON_VERIFIED = "NON_VERIFIED"
}

export enum AppVariables {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  DANGER = "DANGER",
}

export enum ColumnSettingsParentKey {
  ITEM = "items",
  PRICE = "price",
  UNIT = "units",
  AMOUNT = "amount",
  HIDE_COLUMN = "choose_which_column_on_your_invoice_to_hide",
  SHOW_COLUMN = "your_invoice_must_show_at_least_one_of_the_above"
}

export enum CustomTimeType {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  HALF_YEAR = "HALF-YEAR",
  YEAR = "YEAR",
  CUSTOM_RANGE = "CUSTOM-DATE",
}

export enum DataTypeEnum {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
}

export enum DefaultTimeType {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  "CURRENT-WEEK" = "CURRENT-WEEK",
  "LAST-WEEK" = "LAST-WEEK",
  "THIS-MONTH" = "THIS-MONTH",
  "LAST-MONTH" = "LAST-MONTH",
  "THIS-YEAR" = "THIS-YEAR",
  "LAST-YEAR" = "LAST-YEAR",
}

export enum EmailNotificationSettingKey {
  INVENTORY = "inventory_alerts",
  PRODUCTS = "products_updates",
  DAILY_SALES = "daily_sales_summary",
  ANNUAL_SALES = "monthly_and_annual_sales_summary",
  PROGRAMS_EVENT = "upcomming_programs_and_events_for_business",
}

export enum Environment {
  SANDBOX = "SANDBOX",
  PRODUCTION = "PRODUCTION",
}

export enum FeedbacksNavEnums {
  ALL = "ALL",
  SENT = "SENT",
  UNREAD = "UNREAD",
}

export enum FeedbacksTypeEnum {
  GENERAL = "GENERAL",
  TRANSACTION = "TRANSACTION"
}

export enum InputEnum {
  BUTTON = "button",
  CHECKBOX = "checkbox",
  COLOR = "color",
  DATE = "date",
  DATETIME = "datetime-local",
  DECIMAL = "decimal",
  NONE = "none",
  NUMBER = "number",
  EMAIL = "email",
  FILE = "file",
  HIDDEN = "hidden",
  IMAGE = "image",
  MONTH = "month",
  NUMERIC = "numeric",
  PASSWORD = "password",
  RADIO = "radio",
  RANGE = "range",
  RESET = "reset",
  SEARCH = "search",
  SUBMIT = "submit",
  TEL = "tel",
  TEXT = "text",
  TIME = "time",
  URL = "url",
  WEEK = "week"
}

export enum InputValidator {
  INVALID = "INVALID",
  VALID = "VALID"
}

export enum Loading {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  NO_CONTENT = 204,
}

export enum MenuType {
  AUTH = "auth",
  HOME = "home",
  FEEDBACK = "feedback",
  INVENTORY = "inventory",
  USERS = "users-management",
  PROCUREMENT = "procurement",
  SALES = "sales-and-payments",
  SETTINGS = "general-settings",
  SUBSCRIPTIONS = "subscriptions",
  BANKING = "banking-and-payments",
  RESTAURANT_MANAGER = "restaurant-manager",
  CUSTOMER_MANAGEMENT = "customer-management",
}

export enum Method {
  CREATE = "CREATE",
  UPDATE = "UPDATE"
}

export enum CustomerNavEnums {
  CREDIT = "CREDIT",
  EXPORT = "EXPORT",
  FEEDBACKS = "FEEDBACKS",
  PURCHASE_HISTORY = "PURCHASE_HISTORY",
}

export enum InventorySettingNavEnums {
  ITEM = "ITEM",
  WAREHOUSE = "WAREHOUSE",
  MEASUREMENT = "MEASUREMENT",
}

export enum InventoryReportNavEnums {
  VALUATION = "VALUATION",
  PROJECTIONS = "PROJECTIONS",
  STOCK_LEDGER = "STOCK_LEDGER",
  STOCK_TRANSFER = "STOCK_TRANSFER",
  RECIEPTS_HISTORY = "RECIEPTS_HISTORY",
}

export enum PaginationOptions {
  FIVE = 5,
  TEN = 10,
  TWENTY = 20,
  THIRTY = 30,
  FIFTY = 50,
  HUNDRED = 100
}

export enum PaymentMethodEnums {
  CASH = "CASH",
  BANK_TRANSFER = "BANK_TRANSFER",
  PAYMENT_TERMINAL = "PAYMENT_TERMINAL",
}

export enum PropTypeEnums {
  TAX = "TAX",
  ROLE = "ROLE",
  ITEMS = "ITEMS",
  CURRENCY = "CURRENCY",
  CATEGORY = "CATEGORY",
  FEEDBACK = "FEEDBACK",
  TRANSACTION = "TRANSACTION",
  USER_PROFILE = "USER_PROFILE",
  STOCK_ISSUER = "STOCK_ISSUER",
  RECONCILIATION = "RECONCILIATION",
  STOCK_TRANSFER = "STOCK_TRANSFER",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  CUSTOMER_CREDIT = "CUSTOMER_CREDIT",
  PURCHASE_HISTORY = "PURCHASE_HISTORY",
  CONNECTED_DEVICE = "CONNECTED_DEVICE",
  STOCK_ADJUSTMENTS = "STOCK_ADJUSTMENTS",
  BUSINESS_LOCATION = "BUSINESS_LOCATION",
  CUSTOMER_MANAGEMENT = "CUSTOMER_MANAGEMENT",
}

export enum RatingsEnums {
  EXCELLENT = "EXCELLENT",
  VERY_GOOD = "VERY_GOOD",
  GOOD = "GOOD",
  POOR = "POOR",
  VERY_POOR = "VERY_POOR"
}

export enum RatingsActionEnums {
  NON_CLICKABLE = "NON_CLICKABLE",
  CLICKABLE = "CLICKABLE"
}

export enum ReplyTypeEnums {
  INFO,
  INLINE,
  COMPONENT
}

export enum ResetCredentialsEnums {
  URL = "URL",
  PASSWORD = "PASSWORD"
}

export enum SettingsNavEnums {
  TAX = "TAX",
  CURRENCY = "CURRENCY",
  PRINT_TEMPLATE = "PRINT_TEMPLATE",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  BUSINESS_LOCATION = "BUSINESS_LOCATION",
  EMAIL_NOTIFICATION = "EMAIL_NOTIFICATION",
  BUSINESS_INFORMATION = "BUSINESS_INFORMATION",
}

export enum SortOptions {
  LATEST = "LATEST",
  OLDEST = "OLDEST"
}

export enum StatFilterEnums {
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export enum StockAdjustmentTypeEnums {
  RE_COUNT = "RE-COUNT",
  LOSS = "Loss",
  DAMAGE = "DAMAGE",
  RETURN = "RETURN",
  THEFT = "THEFT",
  STOCK_RECEIVED = "STOCK_RECEIVED",
}

export enum StockStatusTypeEnums {
  DRAFT = "DRAFT",
  PARTIAL = "PARTIAL",
  SUBMITTED = "SUBMITTED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export enum SubMenuType {
  ITEMS = "items",
  ROLES = "roles",
  LOGIN = "login",
  PROFILE = "profile",
  REPORTS = "reports",
  SETTINGS = "settings",
  REGISTER = "register",
  DASHBOARD = 'dashboard',
  FEEDBACKS = "feedbacks",
  CATEGORIES = "categories",
  ADD_ADDONS = "add-addons",
  TRANSACTION = "transactions",
  STOCK_ISSUER = "stock-issuer",
  VERIFY_DOMAIN = "verify-domain",
  RECONCILIATION = "reconciliation",
  STOCK_TRANSFER = "stock-transfer",
  RESET_PASSWORD = "reset-password",
  FORGOT_PASSWORD = "forgot-password",
  BILLING_HISTORY = "billing-history",
  ONBOARD_PROFILE = "onboard-profile",
  STOCK_ADJUSTMENTS = "stock-adjustments"
}

export enum SubscriptionDurationEnums {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum SubscriptionNavEnums {
  ADDONS = "ADDONS",
  DEVICES = "DEVICES",
  RENEWAL_HISTORY = "RENEWAL_HISTORY",
  MODULES_FEATURES = "MODULES_FEATURES",
  BUSINESS_LOCATION = "BUSINESS_LOCATION",
}

export enum SubscriptionPaymentStatusEnums {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  SUCCESSFUL = "SUCCESSFUL",
}

export enum TaxTypeEnums {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

export enum TemplateNavEnums {
  SALES = "SALES",
  INVOICES = "INVOICES",
  PURCHASES = "PURCHASES",
}

export enum TemplateTypeEnums {
  BASIC = "BASIC",
  MODERN = "MODERN",
  HYPER = "HYPER",
}

export enum TimeType {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}

export enum ToastTypes {
  ERROR = "error",
  SUCCESS = "success",
}
